@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#legendoritoremove {
  position: relative;
  top: -200px;
  left: 5px;
  width: 150px;
  font-family: Arial;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 5px;
  line-height:18px;
}

#legend {
  position: relative;
  margin-top: -200px;
  left: 5px;
  width: 150px;
  height: 200px;
  font-family: Arial;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 5px;
  line-height:18px;
}

#legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

a.footerLinks:link {  color: black;}
a.footerLinks:visited {  color: black;}


a.bottomMapLinks:link {  color: lightgray;}
a.bottomMapLinks:visited {  color: lightgray;}


